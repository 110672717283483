import React from 'react';
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import Moment from 'react-moment';
import { graphql, Link } from 'gatsby';
import { RichText } from "prismic-reactjs";
import styled from "@emotion/styled";
import colors from "styles/colors";
import Button from "components/_ui/Button";
import Layout from "components/Layout";
import MailchimpForm from "components/MailchimpForm";

const TranscriptHeroContainer = styled("div")`
    max-height: 500px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 3em;

    img {
        width: 100%;
    }
`

const TranscriptHeroAnnotation = styled("div")`
    padding-top: 0.25em;

    h6 {
        text-align: right;
        color: ${colors.grey600};
        font-weight: 400;
        font-size: 0.85rem;
    }

    a {
        color: currentColor;
    }
`

// const ResourcesButton = styled(Link)`
//     margin: 2em auto;
//     display: block;
//     text-align: center;
// `

const TranscriptTitle = styled("div")`
    max-width: 550px;
    margin: 0 auto;
    text-align: center;

    h1 {
        margin-top: 0;
    }
`

const TranscriptBody = styled("div")`
    max-width: 550px;
    margin: 0 auto;

    .block-img {
        margin-top: 3.5em;
        margin-bottom: 0.5em;

        img {
            width: 100%;
        }
    }

    h2, h3 {
        padding-top: 1em;
    }
`

const TranscriptMetas = styled("div")`
    max-width: 550px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    margin-bottom: 2em;
    justify-content: space-between;
    font-size: 0.85em;
    color: ${colors.grey600};
`

const TranscriptAuthor = styled("div")`
    margin: 0;
`

const TranscriptDate = styled("div")`
    margin: 0;
`

const SpeakingLink = styled(Link)`
    margin-top: 3em;
    display: block;
    text-align: center;
    margin-bottom: 7em;
`

const Transcript = ({ transcript, meta }) => {
    return (
        <>
            <Helmet
                title={`${transcript.transcript_title[0].text} | Carolyn Stransky`}
                htmlAttributes={{ lang: "en" }}
                link={[
                    {
                        rel: `canonical`,
                        href: `https://workwithcarolyn.com/transcripts/${transcript._meta.uid}`
                    }
                ]}
                meta={[
                    {
                        name: `description`,
                        content: `${transcript.transcript_preview_description[0].text}`,
                    },
                    {
                        property: `og:title`,
                        content: `${transcript.transcript_title[0].text} | Carolyn Stransky`,
                    },
                    {
                        property: `og:description`,
                        content: `${transcript.transcript_preview_description[0].text}`,
                    },
                    {
                        name: `og:image`,
                        content: `${transcript.transcript_preview_image.url}`,
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        name: `twitter:card`,
                        content: `summary_large_image`,
                    },
                    {
                        name: `twitter:creator`,
                        content: meta.author,
                    },
                    {
                        name: `twitter:title`,
                        content: `${transcript.transcript_title[0].text} | Carolyn Stransky`,
                    },
                    {
                        name: `twitter:description`,
                        content: `${transcript.transcript_preview_description[0].text}`,
                    },
                    {
                        name: `twitter:image`,
                        content: `${transcript.transcript_preview_image.url}`,
                    }
                ].concat(meta)}
            />
            <Layout>
                <TranscriptTitle>
                    {RichText.render(transcript.transcript_title)}
                </TranscriptTitle>
                {transcript.transcript_hero_image && (
                    <TranscriptHeroContainer>
                        <img src={transcript.transcript_hero_image.url} alt="" />
                        <TranscriptHeroAnnotation>
                            {RichText.render(transcript.transcript_hero_annotation)}
                        </TranscriptHeroAnnotation>
                    </TranscriptHeroContainer>
                )}
                <TranscriptMetas>
                    <TranscriptAuthor>
                        {transcript.transcript_author}
                    </TranscriptAuthor>
                    <TranscriptDate>
                        <Moment format="MMMM D, YYYY">{transcript.transcript_date}</Moment>
                    </TranscriptDate>
                </TranscriptMetas>
                {/* {transcript.transcript_resources_link &&
                    <ResourcesButton to={transcript.transcript_resources_link.url} target="_blank" rel="noopener noreferrer">
                        <Button>
                            Resources and slides
                        </Button>
                    </ResourcesButton>
                } */}
                <TranscriptBody>
                    {RichText.render(transcript.transcript_body)}
                    <SpeakingLink to={"/speaking"}>
                        <Button className="Button--secondary">
                            See other talks
                        </Button>
                    </SpeakingLink>
                    <MailchimpForm />
                </TranscriptBody>
            </Layout>
        </>
    )
}

export default ({ data }) => {
    const transcriptContent = data.prismic.allTranscripts.edges[0].node;
    const meta = data.site.siteMetadata;
    return (
        <Transcript transcript={transcriptContent} meta={meta}/>
    )
}

Transcript.propTypes = {
    transcript: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
};

export const query = graphql`
    query TranscriptQuery($uid: String) {
        prismic {
            allTranscripts(uid: $uid) {
                edges {
                    node {
                        transcript_title
                        transcript_hero_image
                        transcript_hero_annotation
                        transcript_preview_image
                        transcript_date
                        transcript_body
                        transcript_author
                        transcript_preview_description
                        _meta {
                            uid
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                author
                baseOG
            }
        }
    }
`